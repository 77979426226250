import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import { useDispatch, useSelector } from "react-redux";
import CategoryListEntry from "../components/CategoryListEntry";
import { getCategories } from "../actions/category.action";

const CategoriesList = () => {
  const dataCategory = useSelector((state) => state.categoryReducer);
  const dataPartner = useSelector((state) => state.partnerReducer);
  const currentCity = useSelector((state) => state.cityReducer);
  const contentCurrentCity = useSelector((state) => state.contentCityReducer);
  const [categoriesCity, setCategoriesCity] = useState([]);
  const dispatch = useDispatch();
  const cityBackground = {
    background: `url(${"https://api.lesbonsplans.pro"}/upload/city/image/${
        currentCity.picture
    }) fixed center bottom/cover`,
  };

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  useEffect(() => {
    if (contentCurrentCity._id === undefined) {
      localStorage.setItem("contentCityMemo", "true");
    }
  }, [contentCurrentCity]);

  useEffect(() => {
    if (Array.isArray(dataCategory) && Array.isArray(dataPartner)) {
      let cityActiveCategories = [];
      dataCategory.forEach((cat) => {
        dataPartner.forEach((part) => {
          if (
              part.city === currentCity._id &&
              part.category.includes(cat.name)
          ) {
            cityActiveCategories = [...cityActiveCategories, cat.name];
          }
        });
      });
      setCategoriesCity(cityActiveCategories);
    }
  }, [dataCategory, dataPartner]);

  return (
      <div className="categories-list-container" style={cityBackground}>
        <Header />
        <section className="categories-list">
          {Array.isArray(dataCategory) &&
              dataCategory
                  .filter((category) => category.isActive)
                  .filter((category) => categoriesCity.includes(category.name))
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((category) => <CategoryListEntry key={category._id} category={category} />)}
        </section>
      </div>
  );
};

export default CategoriesList;