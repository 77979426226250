import React, {useEffect, useState} from "react";
import Header from "../layouts/Header";
import PartnerListEntry from "../components/PartnerListEntry";
import {useSelector} from "react-redux";
import {isEmpty} from "../assets/Utils";
import {useParams} from "react-router";

const PartnersList = () => {
    const {citysubDomain, categorysubDomain} = useParams();
    const dataPartner = useSelector((state) => state.partnerReducer);
    const [currentCity, setCurrentCity] = useState({});
    const [currentCategory, setcurrentCategory] = useState({});
    const [filteredPartner, setFilteredPartner] = useState([]);
    const [currentLocation, setCurrentLocation] = useState({});
    const [sortedPartner, setSortedPartner] = useState([]);

    // Collecte des données de localisation
    const collectDataLocation = (pos) => {
        const crd = pos.coords;
        const data = {
            latitude: crd.latitude,
            longitude: crd.longitude,
        };
        setCurrentLocation(data);
    };

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(collectDataLocation);
    }, []);

    // Récupération des données de la ville
    useEffect(() => {
        const fetchCity = async () => {
            try {
                const response = await fetch(`https://api.lesbonsplans.pro/city/search?subDomain=${citysubDomain}`);
                const data = await response.json();
                setCurrentCity(data);
            } catch (error) {
                console.error("Error fetching city data:", error);
            }
        };

        fetchCity();
    }, [citysubDomain]);

    // Récupération des données de la catégorie
    useEffect(() => {
        const fetchCategory = async () => {
            if (!isEmpty(currentCity)) {
                try {
                    const response = await fetch(`https://api.lesbonsplans.pro/category/search?subDomain=${categorysubDomain}`);
                    const data = await response.json();
                    setcurrentCategory(data);
                } catch (error) {
                    console.error("Error fetching category data:", error);
                }
            }
        };

        fetchCategory();
    }, [categorysubDomain, currentCity]);

    // Récupération des partenaires
    useEffect(() => {
        const fetchPartner = async () => {
            if (!isEmpty(currentCity) && !isEmpty(currentCategory) && currentCategory.name) {
                try {
                    const response = await fetch(`https://api.lesbonsplans.pro/partner/searchByCityAndCategory?cityId=${currentCity._id}&categoryName=${currentCategory.name}`);
                    const data = await response.json();
                    setFilteredPartner(data.filter(part => part.isActive));
                } catch (error) {
                    console.error('Error fetching partners:', error);
                }
            }
        };

        fetchPartner();
    }, [currentCity, currentCategory]);

    // Fonction pour calculer la distance en utilisant la formule de Haversine
    const haversineDistance = (partner) => {
        const { latitude, longitude } = currentLocation;
        const [partnerLat, partnerLng] = partner.coordinates;

        const R = 6371; // Rayon de la Terre en km
        const dLat = (partnerLat - latitude) * (Math.PI / 180);
        const dLng = (partnerLng - longitude) * (Math.PI / 180);

        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(latitude * (Math.PI / 180)) * Math.cos(partnerLat * (Math.PI / 180)) *
            Math.sin(dLng / 2) * Math.sin(dLng / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c; // Distance en kilomètres
    };

    // Trier les partenaires en fonction de la distance
    const sortPartners = (partners) => {
        const distances = partners.map((partner) => ({
            ...partner,
            distance: haversineDistance(partner)
        }));

        return distances.sort((a, b) => a.distance - b.distance);
    };

    useEffect(() => {
        if (!isEmpty(filteredPartner) && !isEmpty(currentLocation)) {
            setSortedPartner(sortPartners(filteredPartner));
        }
    }, [filteredPartner, currentLocation]);

    const cityBackground = {
        background: !isEmpty(currentCity) && currentCity.picture
            ? `url(${"https://api.lesbonsplans.pro"}/upload/city/image/${currentCity.picture}) fixed center bottom/cover`
            : 'none',
    };

    return (
        <div className="partners-list-container" style={cityBackground}>
            <Header navPath="categories" stringBtn="Retourner aux catégories"/>
            <h1>{currentCategory.name}</h1>
            <section className="partners-list">
                {!isEmpty(sortedPartner) &&
                    sortedPartner.map((part) => (
                        <PartnerListEntry key={part._id} partner={part} distance={part.distance.toFixed(2)} />
                    ))
                }
            </section>
        </div>
    );
};

export default PartnersList;
