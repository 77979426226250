import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";
import { setCategory } from "../actions/category.action";

const createOption = (label) => ({
  label,
  value: label,
});

const CategorySelector = ({ onChange, value }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const dataCategory = useSelector((state) => state.categoryReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    let tempOptions = [];
    dataCategory.forEach((category) => {
      tempOptions.push(createOption(category.name));
    });
    tempOptions.sort((a, b) => a.label.localeCompare(b.label));
    setOptions(tempOptions);
  }, [dataCategory]);

  const handleCreate = (inputValue) => {
    setIsLoading(true);
    const newCategory = { name: inputValue, isActive: true };
    dispatch(setCategory(newCategory));
    const newOption = createOption(inputValue);
    setOptions((prev) => [...prev, newOption]);
    onChange([...(value || []), newOption]);
    setIsLoading(false);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      margin: "20px 0",
      padding: "5px",
      minWidth: "50%",
      backgroundColor: "#e2b259",
      border: "2px solid #fff",
      boxShadow: "none",
      transition: "0.2s ease",
      "&:hover": {
        border: "2px solid #ccc",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#b1852d" : "#e2b259",
      color: state.isFocused ? "white" : "black",
      ":active": {
        backgroundColor: "white",
        color: "black",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "black",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#b1852d",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "white",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "white",
      "&:hover": {
        backgroundColor: "red",
        color: "white",
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "white",
      transition: "0.2s ease",
      "&:hover": {
        color: "#ccc",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#e2b259",
      border: "2px solid white",
      borderRadius: "4px",
      marginTop: "4px",
      zIndex: 9999,
    }),
  };

  return (
    <CreatableSelect
      isMulti
      isClearable
      isDisabled={isLoading}
      isLoading={isLoading}
      onChange={onChange}
      onCreateOption={handleCreate}
      options={options}
      value={value}
      styles={customStyles}
      placeholder="Sélectionnez ou créez une catégorie"
    />
  );
};

export default CategorySelector;
