import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../components/LogoCity";
import { useDispatch, useSelector } from "react-redux";
import { getCity } from "../actions/city.action";
import { getContentCity } from "../actions/contentCity.action";
import { isEmpty } from "../assets/Utils";

const Home = () => {
    const currentCity = useSelector((state) => state.cityReducer);
    const contentCurrentCity = useSelector((state) => state.contentCityReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("contentCityMemo")) {
            localStorage.removeItem("contentCityMemo");
            navigate("/");
            window.location.reload();
        }
    }, [navigate]);

    const onClickRedirectCities = async () => {
        dispatch(getCity());
        dispatch(getContentCity());
        window.location.reload();
    };

    const cityBackground = {
        background: `url(${"https://api.lesbonsplans.pro"}/upload/city/image/${
            currentCity.picture
        }) fixed center bottom/cover`,
    };

    return (
        <div className="home-container nav-page-container" style={cityBackground}>
            <section className="logo-container">
                <Logo />
            </section>
            <section className="nav-container">
                <nav className="nav-categories">
                    <ul>
                        <li>
                            <NavLink to="/" onClick={() => onClickRedirectCities()}>
                                <i className="bx bxs-home"></i>Villes
                            </NavLink>
                        </li>
                        {!isEmpty(contentCurrentCity.magazines) && (
                            <li>
                                <NavLink to="/magazines">
                                    <i className="bx bxs-news"></i>Magazines
                                </NavLink>
                            </li>
                        )}
                        <li>
                            <NavLink to="/categories">
                                <i className="bx bxs-heart"></i>Partenaires
                            </NavLink>
                        </li>
                        {!isEmpty(contentCurrentCity.calendarLink) && (
                            <li>
                                <NavLink to="/agenda">
                                    <i className="bx bxs-calendar"></i>Agenda
                                </NavLink>
                            </li>
                        )}
                        <li>
                            <NavLink to="/decouverte">
                                <i className="bi bi-search-heart"></i>Découverte
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/reseaux-sociaux">
                                <i className="bx bxs-chat"></i>Réseaux sociaux
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/contact">
                                <i className="bx bxs-contact"></i>Contact
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            </section>
        </div>
    );
};

export default Home;